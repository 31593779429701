@tailwind components;

form .input_container input {
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  color: #808080;
  margin-bottom: 8px;
  padding: 10px 10px;
  width: 100%;
}
form input::placeholder {
  color: #808080;
}

form input:focus {
  border: 1px solid #808080;
  outline: none;
}

.separator span {
  background-color: #dbdbdb;
  height: 1px;
  width: calc(100% - 10px);
}

.separator .or {
  color: #808080;
  font-weight: bold;
}

.separator {
  padding: 0 20px;
}
.separator span:first-child {
  margin-right: 10px;
}
.separator span:last-child {
  margin-left: 10px;
}

@layer components {
  .login_container {
    @apply max-w-[1560px] w-full h-screen top-0 pt-16  mx-auto bg-gray_bg;
  }
  .form_container {
    @apply max-w-[350px] w-[90%] bg-white border-[1px] m-auto min-h-[60vh] h-fit  p-5;
  }
  .login_form {
    @apply flex flex-col w-full h-full justify-center gap-5;
  }
  .input_container {
    @apply flex flex-col gap-3;
  }
  .input_container button {
    @apply bg-[#34c684] rounded-[5px] text-white font-bold h-[35px] w-full disabled:opacity-60;
  }
  .sign_container {
    @apply max-w-[350px] w-[90%] bg-white border-[1px] m-auto min-h-[10vh] h-fit p-5 my-3 flex gap-3 justify-center;
  }
}
