* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.main-panel {
  background-color: black;
  width: 100%;
  height: 670px;
}
.bgg {
  background-color: rgb(94, 94, 104);
  height: 60px;
  width: 85%;
  margin: auto;
}
.dashboard {
  width: 85%;
  margin: auto;
}
.border-line {
  border: 1px solid gray;
}
.ff {
  font-size: 16px;
}
.panel {
  display: flex;
}
.main--side {
  width: 77%;
}
.main-side {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 50px;
}
.main---side {
  padding: 5px;
  justify-content: space-between;
}
.panel-image img {
  width: 3%;
  border-radius: 13px;
}
.cardOne {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  height: 150px;
  justify-content: space-between;
  padding: 10px;
}
.cardOne h5 {
  font-size: 14px;
}
.card-color {
  background-color: #ff264a;
  width: 15%;
  height: 30px;
  border-radius: 26px;
}
.card-colorTwo {
  background-color: orangered;
  width: 15%;
  height: 30px;
  border-radius: 26px;
}
.card-colorThr {
  background-color: #12cbc4;
  width: 15%;
  height: 30px;
  border-radius: 26px;
}
.card-colorFr {
  background-color: #feec1e;
  width: 15%;
  height: 30px;
  border-radius: 26px;
}
.card-arr {
  color: orangered;
}
.tbOne {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  height: 55px;
}
.tabsOne {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  height: 370px;
  width: 100%;
  padding-top: 20px;
}
.containers {
  position: relative;
  border: 1px solid black;
  width: 85%;
  margin: auto;
  height: 320px;
  border-top: none;
  border-right: none;
  z-index: 0;
  background-color: #f9f4f4;
}
.v-meter {
  position: relative;
  top: 0px;
  left: -10px;
  width: 570px;
  height: 300px;
  z-index: 50;
}
.v-meter li {
  position: relative;
  left: -11px;
  height: 50px;
  border-bottom: 1px solid #1785a7;
}
.v-meter div {
  position: absolute;
  width: 80px;
  font-size: 16px;
  left: -50px;
  color: black;
  padding-top: 35px;
}
.bar {
  width: 30px;
  height: 35%;
  background-color: #328036;
  position: absolute;
  left: 10%;
  bottom: 5px;
  z-index: 1000;
  margin: 0 40px;
}
.bar ::after {
  content: '';
  width: 30px;
  height: 20px;
  background-color: #328036;
  position: absolute;
  top: -20px;
  left: -10px;
}
.four {
  left: 25%;
  height: 50%;
}
.five {
  left: 40%;
  height: 65%;
}
.six {
  left: 55%;
  height: 80%;
}
.tabsTwo {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  height: 370px;
  /* width: 330px; */
}
.x-box {
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  background-color: green;
  margin: auto;

  background: repeating-conic-gradient(
    from 0deg,
    #ff264a 0deg calc(3.6deg * 35),
    #feec1e calc(3.6deg * 35) calc(3.6deg * 70),
    #12cbc4 calc(3.6deg * 70) calc(3.6deg * 100)
  );
}
.x--box {
  padding-top: 75px;
}
.x-box-cont {
  width: 90%;
  font-size: 13px;
  margin: auto;
  justify-content: space-between;
}
.x-box-cont h5 {
  font-size: 13px;
  text-transform: uppercase;
}
.x-box-cont strong {
  display: block;
  margin-bottom: 10px;
}
.parag {
  color: #ff264a;
}
.parags {
  color: #feec1e;
}
.paragg {
  color: #12cbc4;
}
.rr {
  width: 25%;
}
.tb {
  width: 85%;
  margin: auto;
  padding-top: 50px;
}
.table {
  height: 300px;
}
.traff {
  padding: 15px;
  font-weight: 700;
}
.mhs h4 {
  font-size: 13px;
}
.sunny_register {
  width: 100%;
  height: 550px;
}
.sunny__register {
  height: 450px;
}
.sun--reg {
  width: 85%;
  margin: auto;
}
.sun--reg {
  width: 80%;
  margin: auto;
}
.sun_regtext h5 {
  font-size: 13px;
}
.line {
  text-decoration: none;
}
.signup-panel {
  width: 25%;
}
.sign-nav{
  width: 75%;
}
.signnav-color{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 50px;
}
.users {
  width: 25%;
}
.pp {
  width: 75%;
}
.jj {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 50px;
  justify-content: space-between;
}
.newsOne {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  height: 300px;
}
.newsTwo {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  height: 300px;
}
.research-color {
  width: 75%;
}
.blogs {
  width: 25%;
}
.blogOne {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  height: 200px;
}
.blg {
  background-color: red;
  height: 50%;
}
.hh {
  border: none;
}
.a-btn {
  margin-top: -20px;
  font-weight: 600;
}
.dark {
  background-color: black;
  color: white;
}
.dark_btn {
  background: transparent;
  background-color: blue;
  color: white;
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  font-weight: 600;
}
.bloglist {
  width: 95%;
  margin: auto;
}

@media (max-width: 1024px) {
  .main-panel {
    height: 700px;
  }
  .drk {
    margin-top: -25px;
  }
  .v-meter {
    width: 425px;
    margin: auto;
  }
}
@media (max-width: 800px) {
  .main-panel {
    height: 780px;
  }
  .cardOne {
    height: 180px;
  }
  .v-meter {
    width: 290px;
  }
}
@media (max-width: 500px) {
  .accordion {
    width: 70%;
  }
  .main-panel {
    height: 1070px;
    width: 150px;
  }
  .tabsTwo {
    height: 250px;
  }
  .v-meter {
    width: 175px;
    margin: auto;
  }
  .main--side {
    width: 300px;
    margin-left: 40px;
  }
  .research-color {
    width: 100%;
    margin-left: 43px;
    padding: 3px;
  }
  .pp {
    width: 500px;
    margin-left: 43px;
    padding: 3px;
  }
  .newsletter {
    width: 90%;
    margin: auto;
  }
  .sign-nav{width: 275px;margin-left: 43px;}
}
